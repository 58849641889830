.hero-text-animation {
    color: transparent;
    -webkit-text-stroke: 1px black;
   background: linear-gradient(to right,  #6633CC 0%, #FF44B8 50%, white 50%, white 75%);
    background-size: 200% 100%; 
    background-position: -100% 0;
    transition: background-position 2s ease-out;
    background-clip: text;
    -webkit-background-clip: text; 
    font-family: "Open Sans",sans-serif;
    
  }
  
  .hero-container:hover  .hero-text-animation{
    background-position: 0 100%; 
    -webkit-text-stroke: 0px black;
  }

  .large-gr{
    background: hsla(217, 100%, 50%, 1);

background: linear-gradient(270deg, hsla(266, 95%, 24%, 1) 0%, hsla(208, 91%, 58%, 1) 100%);

background: -moz-linear-gradient(270deg, hsla(266, 95%, 24%, 1) 0%, hsla(208, 91%, 58%, 1) 100%);

background: -webkit-linear-gradient(270deg, hsla(266, 95%, 24%, 1) 0%, hsla(208, 91%, 58%, 1) 100%);


  }

 
  
  .bush {
    animation: sway 4s ease-in-out infinite alternate-reverse;
  }
  .palm-tree {
    animation: sway-2 6s ease-in-out infinite alternate-reverse;
  }
  .clouds {
    animation: cloudMove 60s linear infinite;
   
   
  }
  @keyframes sway {
    0% {
        transform: rotateZ(0deg);
      }
      50% {
        transform: rotateZ(-3deg);
      }
      100% {
        transform: rotateZ(3deg);
      }
  }

  @keyframes sway-2 {
    0% {
        transform: rotateZ(0deg);
      }
      50% {
        transform: rotateZ(3deg);
      }
      100% {
        transform: rotateZ(-3deg);
      }
  }
  @keyframes cloudMove {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-200%);
    }
  }


@media (min-width:0px) {
    .reality {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        /* overflow: hidden; */
        margin-left: auto;
        margin-right: auto;
       
        position: relative;
        top: 0px;
        width: 100%;
        height: 250px;
        transform: scale(0.75);
      }
      .reality .perspective-text {
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 10px;
        font-weight: 900;
        letter-spacing: 5px;
        text-transform: uppercase;
        height: 200px;
        max-width: 400px;
        min-width: 180px;
        position: relative;
        left: -100px;
      }
      .reality .perspective-line {
        height: 44px;
        overflow: hidden;
        position: relative;
        top: 0px;
      }
      .reality > .perspective-text .perspective-line > p {
        margin: 0;
        height: 50px;
        line-height: 50px;
        transition: all 0.5s ease-in-out;
        color: #fff;
        padding-left: 30px;
      }
      .reality .perspective-line:nth-child(odd) {
        transform: skew(60deg, -30deg) scaleY(0.667);
      }
      .reality .perspective-line:nth-child(even) {
        transform: skew(0deg, -30deg) scaleY(1.337);
      }
      .reality .perspective-text:hover p {
        transform: translate(0, -50px);
      }
      .reality .perspective-line:nth-child(1) {
        left: 29px;
      }
      .reality .perspective-line:nth-child(2) {
        left: 58px;
        background: #ff66c5;
      }
      .reality .perspective-line:nth-child(3) {
        left: 83px;
        background: #6634cc;
      }
      .reality .perspective-line:nth-child(4) {
        left: 108px;
        background: #00b9ff;
      }
      .reality .perspective-line:nth-child(5) {
        left: 134px;
      }
}
  
@media (max-width:450px) {
    .reality {
        height: 200px;
        transform: scale(0.5);
      }
}
  

  .white-to-gr{
    background: linear-gradient(to right,  #6633CC 0%, #FF44B8 50%, white 50%, white 75%) !important;
    background-size: 200% 100% !important; 
    background-position: -100% 0 !important;
    transition: background-position 0.5s ease-out ;
    
  }
  
  
  .white-to-gr:hover  {
    background-position: 0 100% !important; 
   
  
  }
  
  .white-to-gr:hover > span{
    -webkit-text-fill-color:white ;
  }

  .discover-mission::after {
    content: "";
    position: absolute;
    bottom: -14px; 
    left: 50%; 
    transform: translateX(-50%) scale(-1); 
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px; 
    border-color: transparent transparent #fff transparent; 
  }
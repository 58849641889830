:root {
    /* Color styles */
    --link: #ff1faa;
    --primary-1: #140a29;
    --primary-2: #291452;
    --primary-3: #3d1f7a;
    --primary-4: #5229a3;
    --primary-5: #6633cc;
    --primary-6: #8055d5;
    --primary-7: #9977dd;
    --primary-8: #b399e6;
    --primary-9: #ccbbee;
    --primary-10: #e6ddf7;
    --secondary-1: #390023;
    --secondary-2: #720047;
    --secondary-3: #ac006a;
    --secondary-4: #e5008e;
    --secondary-5: #ff1faa;
    --secondary-6: #ff44b8;
    --secondary-7: #ff6ac6;
    --secondary-8: #ff8fd4;
    --secondary-9: #ffb4e3;
    --secondary-10: #ffdaf1;
    --neutral-1: #191919;
    --neutral-2: #313131;
    --neutral-3: #474747;
    --neutral-4: #5a5a5a;
    --neutral-5: #737373;
    --neutral-6: #9e9e9e;
    --neutral-7: #bcbcbc;
    --neutral-8: #cccccc;
    --neutral-9: #dddddd;
    --neutral-10: #ececec;
    --neutral-11: #f6f6f6;
    --neutral-12-white: #ffffff;
    --feedback-failure-1: #730404;
    --feedback-failure-2: #931515;
    --feedback-failure-3: #ab1616;
    --feedback-failure-4: #bd2929;
    --feedback-failure-5: #cb3434;
    --feedback-failure-6: #d44242;
    --feedback-failure-7: #df5151;
    --feedback-failure-8: #ed6a6a;
    --feedback-failure-9: #ff9595;
    --feedback-failure-10: #ffbaba;
    --feedback-failure-11: #ffd5d5;
    --feedback-failure-12: #fff3f3;
    --feedback-success-1: #00613e;
    --feedback-success-2: #008052;
    --feedback-success-3: #00955f;
    --feedback-success-4: #00a76b;
    --feedback-success-5: #00b674;
    --feedback-success-6: #00c27c;
    --feedback-success-7: #0cce88;
    --feedback-success-8: #2ae2a0;
    --feedback-success-9: #59f2bb;
    --feedback-success-10: #78fecc;
    --feedback-success-11: #adf9dd;
    --feedback-success-12: #e8fff7;
  
    /* Text-size styles */
    /* base size: title---title-5---regular (16px) */
    --title---large-title---regular: 2.25rem;
    --title---large-title---medium: 2.25rem;
    --title---large-title---semibold: 2.25rem;
    --title---large-title---bold: 2.25rem;
    --title---title-1---regular: 1.88rem;
    --title---title-1---medium: 1.88rem;
    --title---title-1---semibold: 1.88rem;
    --title---title-1---bold: 1.88rem;
    --title---title-2---regular: 1.62rem;
    --title---title-2---medium: 1.62rem;
    --title---title-2---semibold: 1.62rem;
    --title---title-2---bold: 1.62rem;
    --title---title-3---regular: 1.5rem;
    --title---title-3---medium: 1.5rem;
    --title---title-3---semibold: 1.5rem;
    --title---title-3---bold: 1.5rem;
    --title---title-4---regular: 1.25rem;
    --title---title-4---medium: 1.25rem;
    --title---title-4---semibold: 1.25rem;
    --title---title-4---bold: 1.25rem;
    --title---title-5---regular: 1rem;
    --title---title-5---medium: 1rem;
    --title---title-5---semibold: 1rem;
    --title---title-5---bold: 1rem;
    --title---title-6---regular: 0.75rem;
    --title---title-6---medium: 0.75rem;
    --title---title-6---semibold: 0.75rem;
    --title---title-6---bold: 0.75rem;
    --body---caption: 0.75rem;
    --body---overline: 0.62rem;
    --body---body-1---regular: 1.12rem;
    --body---body-1---medium: 1.12rem;
    --body---body-2---regular: 1rem;
    --body---body-2---medium: 1rem;
    --body---body-3---regular: 0.88rem;
    --body---body-3---medium: 0.88rem;
    --body---body-4---regular: 0.62rem;
    --body---body-4---medium: 0.62rem;
  
    /* Effect styles */
    --inner-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.2);
    --f-a-b: 0px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 1px rgba(0, 0, 0, 0.25);
    --toggle: -1px 0px 6px rgba(0, 0, 0, 0.3);
    --drag: 0px 3px 11px rgba(0, 0, 0, 0.3);
    --popup--shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
    --elevation--medium: 1px 2px 7px rgba(0, 0, 0, 0.18),
      1px 1px 1px rgba(0, 0, 0, 0.05);
    --elevation--small: 1px 1px 4px rgba(0, 0, 0, 0.1),
      1px 1px 0px rgba(0, 0, 0, 0.02);
    --elevation--large: -1px 6px 15px rgba(0, 0, 0, 0.2),
      1px 1px 3px rgba(0, 0, 0, 0.1);
    --elevation--large-dark: -1px 6px 15px rgba(0, 0, 0, 0.4),
      1px 1px 3px rgba(0, 0, 0, 0.4);
    --elevation--medium-dark: 1px 2px 7px rgba(0, 0, 0, 0.36),
      1px 1px 1px rgba(0, 0, 0, 0.1);
    --elevation--small-dark: 1px 1px 4px rgba(0, 0, 0, 0.2),
      1px 1px 0px rgba(0, 0, 0, 0.04);
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@500;600&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Carter+One&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

  body {

    font-family: 'Inter', sans-serif !important;
    line-height: 1.2 !important;
    letter-spacing: 0.5px;
    color: #191919;
  }

  .MuiInputBase-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
   
    -webkit-text-fill-color: #191919 !important;
    caret-color: #191919 !important;
    border-radius: inherit !important;
  }
  
  :root.dark .MuiInputBase-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #191919 inset !important;
    -webkit-text-fill-color: #fff !important;
    caret-color: #fff !important;
    border-radius: inherit !important;
  }

  .gr-dark .MuiInputBase-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #411366 inset !important;
   
    -webkit-text-fill-color: white !important;
    caret-color: #191919 !important;
    border-radius: inherit !important;
  }

  .video-background-container {
    position: relative;
 
 
    overflow: hidden;
  }
  
  .video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
  }

  
.circle::before,
.circle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;  
  -webkit-backdrop-filter:blur(1rem);
  backdrop-filter: blur(1rem);
  background-color: white;
  -webkit-animation: wave 2s linear infinite;
  animation: wave 2s linear infinite;
 
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style:preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.circle::before {
  opacity: 1;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.circle::after {
  opacity: 0.3;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

@keyframes wave {
  to {
    opacity: 0;
    transform: scale(2);
    transform-origin: center center;
}
}

.cover-animation{
  background-size: cover;
  background-position: center center;
  animation: zoom-in 60s linear  ;
  
}

@keyframes zoom-in {
  0% {
   transform: scale(1);
    opacity: 0.8;
  }
  
  100% {
    transform: scale(3);
    opacity: 1;
  
  }
}

.countdown {
  font-family: 'Martian Mono', monospace;
}

.btn:disabled{
  cursor: not-allowed !important;
}

a{
  color: var(--link);
}

@media (min-width:370px) {
  .vi__container #field-2:after {
    content: '-';
    font-size: 60px;
    color: #BCBCBC;
    font-weight: 500;
    top: 50%;
    right: 0;
    transform: translate(150%, -50%);
    position: absolute;
  }
  
  .vi__container #field-2 {
    position: relative;
    margin-right: 3rem;
  }
  
  .vi__16 #field-2:after {
    content: '-';
    font-size: 60px;
    color: #BCBCBC;
    font-weight: 500;
    top: 50%;
    right: 0;
    transform: translate(150%, -50%);
    position: absolute;
  }
  
  .vi__16 #field-2 {
    position: relative;
    margin-right: 3rem;
  }
}



 .MuiPaper-root::-webkit-scrollbar-track {
  border-radius: 18px;
  background-color: #22222250;
}

.MuiPaper-root::-webkit-scrollbar {
  width: 8px;
  background-color: #22222250;
}

.MuiPaper-root::-webkit-scrollbar-thumb {
  border-radius: 8px;

  background-color: #343434;
}



.image-1{
  background-image: url('/src/assets/1.webp');
}

.image-2{
  background-image: url('/src/assets/2.webp');
}

.image-3{
  background-image: url('/src/assets/3.webp');
}


.image-4{
  background-image: url('/src/assets/4.webp');
}

.image-5{
  background-image: url('/src/assets/5.webp');
}
.image-6{
  background-image: url('/src/assets/6.webp');
  }
  
  .image-7{
  background-image: url('/src/assets/7.webp');
  }
  
  .image-8{
  background-image: url('/src/assets/8.webp');
  }
  
  .image-9{
  background-image: url('/src/assets/9.webp');
  }
  
  .image-10{
  background-image: url('/src/assets/10.webp');
  }
  
  .image-11{
  background-image: url('/src/assets/11.webp');
  }
  
  .image-12{
  background-image: url('/src/assets/12.webp');
  }
  
  .image-13{
  background-image: url('/src/assets/13.webp');
  }
  
  .image-14{
  background-image: url('/src/assets/14.webp');
  }
  
  .image-15{
  background-image: url('/src/assets/15.webp');
  }
  
  .image-16{
  background-image: url('/src/assets/16.webp');
  }
  
  .image-17{
  background-image: url('/src/assets/17.webp');
  }
  
  .image-18{
  background-image: url('/src/assets/18.webp');
  }
  
  .image-19{
  background-image: url('/src/assets/19.webp');
  }
  
  .image-20{
  background-image: url('/src/assets/20.webp');
  }
  .image-21 {
    background-image: url('/src/assets/21.webp');
  }
  .image-22 {
    background-image: url('/src/assets/22.webp');
  }
  .image-23 {
    background-image: url('/src/assets/23.webp');
  }
  .image-24 {
    background-image: url('/src/assets/24.webp');
  }
  .image-25 {
    background-image: url('/src/assets/25.webp');
  }
  .image-26 {
    background-image: url('/src/assets/26.webp');
  }
  .image-27 {
    background-image: url('/src/assets/27.webp');
  }
  .image-28 {
    background-image: url('/src/assets/28.webp');
  }
  .image-29 {
    background-image: url('/src/assets/29.webp');
  }
  .image-30 {
    background-image: url('/src/assets/30.webp');
  }
  .image-31 {
    background-image: url('/src/assets/31.webp');
  }
  .image-32 {
    background-image: url('/src/assets/32.webp');
  }
  .image-33 {
    background-image: url('/src/assets/33.webp');
  }
  .image-34 {
    background-image: url('/src/assets/34.webp');
  }
  .image-35 {
    background-image: url('/src/assets/35.webp');
  }
  .image-36 {
    background-image: url('/src/assets/36.webp');
  }
  .image-37 {
    background-image: url('/src/assets/37.webp');
  }
  .image-38 {
    background-image: url('/src/assets/38.webp');
  }
  .image-39 {
    background-image: url('/src/assets/39.webp');
  }
  .image-40 {
    background-image: url('/src/assets/40.webp');
  }
  .image-41 {
    background-image: url('/src/assets/41.webp');
  }
  .image-42 {
    background-image: url('/src/assets/42.webp');
  }
  .image-43 {
    background-image: url('/src/assets/43.webp');
  }
  .image-44 {
    background-image: url('/src/assets/44.webp');
  }
  .image-45 {
    background-image: url('/src/assets/45.webp');
  }
  .image-46 {
    background-image: url('/src/assets/46.webp');
  }
  .image-47 {
    background-image: url('/src/assets/47.webp');
  }
  .image-48 {
    background-image: url('/src/assets/48.webp');
  }
  .image-49 {
    background-image: url('/src/assets/49.webp');
  }
  .image-50 {
    background-image: url('/src/assets/50.webp');
  }
  .image-51 {
    background-image: url('/src/assets/51.webp');
  }
  .image-52 {
    background-image: url('/src/assets/52.webp');
  }
  .image-53 {
    background-image: url('/src/assets/53.webp');
  }
  .image-54 {
    background-image: url('/src/assets/54.webp');
  }
  .image-55 {
    background-image: url('/src/assets/55.webp');
  }
  .image-56 {
    background-image: url('/src/assets/56.webp');
  }
  .image-57 {
    background-image: url('/src/assets/57.webp');
  }
  .image-58 {
    background-image: url('/src/assets/58.webp');
  }
  .image-59 {
    background-image: url('/src/assets/59.webp');
  }
  .image-60 {
    background-image: url('/src/assets/60.webp');
  }
  .image-61 {
    background-image: url('/src/assets/61.webp')}
  


  .animate:nth-child(even){

    animation: godown infinite  linear 40s forwards;
    transform: translateY(0);
  }
  .animate:nth-child(odd){

    animation: goup infinite 40s linear forwards;
   
  }

  @keyframes godown {
    from{
      transform: translateY(-100%);
    }
     to{
      transform: translateY(0);
     } 
  }
  @keyframes goup {
    from{
      transform: translateY(0);
    }
     to{
      transform: translateY(-100%);
     } 
 }
 .animate-parent:hover .animate > div{
  filter: grayscale(1);
 }
 .animate-parent:hover .animate:hover > div{
  filter: grayscale(0);
 }
  .animate:hover  {
    animation-play-state: paused; 
   
   
  }
.animate:hover >div{
  transform: scale(1.05);
  transition: all 300ms ease-in-out;
 
}

  .animate::not(:hover)  {
    animation-play-state: running; 
    transform: scale(1);
  }


  .test-animate{
    animation: moveSlideshow 10s linear infinite;
  }

  @keyframes moveSlideshow {
    100% { 
      transform: translateY(-66.6666%);  
    }
  }




  .test-animate:nth-of-type(2){
   left: 40%;
   background-color: red;
  }


  #cone:hover{
    animation: tada2;
    animation-duration: 1s;
  }

  @keyframes tada2 {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    10%,
    20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%,
    60%,
    80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }
  


  .text-gr{
    background: linear-gradient(90.15deg, #8B46E0 0.13%, #DB1EB2 142.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
  }
  .text-gr-animation{
    background: linear-gradient(99.09deg, #6633CC, #FF44B8, #FF44B8, #6633CC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
	background-size: 400% 400%;
	animation: text-gradient 5s ease infinite;
  }

  @keyframes text-gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @media (max-width:380px) {
    .hero-title{
      font-size: 32px !important;
    }
  }

  .text-stroke {
    -webkit-text-stroke: 3px black;
  }
@media (max-width:768px) {
  .text-stroke {
    -webkit-text-stroke: 1px black;
  }
}
  .archivo{
    font-family: 'Archivo Black', sans-serif;
  }

  @media (max-width:350px) {
    .btn-gr {
     font-size: 10px !important;
     padding: 0.5rem 1.25rem !important;
    }
  }


  


  @keyframes countdown {
    0% {
    color: rgba(34, 34, 34, 1);
    }
    50% {
      color: rgba(34, 34, 34, 0.5);
    }
    100% {
      color: rgba(34, 34, 34, 1);
    }
  }
  
  .countdown-end >span {
    animation: countdown 1s infinite;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 18px;
    background-color: #fff;
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: #fff;
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
  
    background-color: #f0f0f0;
  }

  .two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  button > svg{
   
    pointer-events: none !important;
  }

  input[type='date'] {
    position: relative;
  }
  
  input[type='date']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .gradient-animations{
    background: linear-gradient(99.09deg, #6633CC, #FF44B8, #FF44B8, #6633CC);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .animate-infinite-hr-parent{
    overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  }
  .animate-infinite-hr{
   
    animation: animate-hr infinite linear forwards  ;
    animation-duration: 120s ;
  }

  @media (hover: hover) {
    .animate-infinite-hr:hover  {
      animation-play-state: paused;
      
     }
  }
  
  @media  (max-width:768px) {
    .animate-infinite-hr{
   
      animation-duration: 45s !important;
      
    }
  }

  @keyframes animate-hr {
    to{
      transform: translateX(-400%);
    }
  }


  @media (hover: hover) {
    .image-zoom-in:hover >img  {
      transform: scale(1.25);
      
     }
  }

  
  .emoji-picker >section{
    width: 200px !important;
    background-color: red;
    box-shadow: 20px 20px 20px red;
  }

  .emoji-picker::host {
    background-color: #f5f5f5 !important;
  }

  .emoji-picker section::slotted() {
    font-size: 40px  !important;
  }


  .emoji-mart { width: 200px !important }


  button:disabled{
    cursor: not-allowed;
  }

 @media (min-width:1280px) {
  html {
    /* zoom: 1; */
  }
 }


 .vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}


.title-text-shadow{
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) 
}

@media (max-width:1024px) {
  .title-text-shadow{
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) 
  }
}

.floating-animation{
  animation: float 6s ease-in-out infinite;
}
.floating-animation-2{
  animation: float-2 6s ease-in-out infinite;
}
@keyframes float {
	0% {
	
		transform: translateY(0px);
	}
	50% {
		
		transform: translateY(-20px);
	}
	100% {
	
		transform: translateY(0px);
	}
}

@keyframes float-2 {
	0% {
	
		transform: translateY(0px);
	}
	50% {
		
		transform: translateY(-40px);
	}
	100% {
	
		transform: translateY(0px);
	}
}


@media (max-width:640px) {
  @keyframes float {
    0% {
    
      transform: translateY(0px);
    }
    50% {
      
      transform: translateY(-15px);
    }
    100% {
    
      transform: translateY(0px);
    }
  }
  @keyframes float-2 {
    0% {
    
      transform: translateY(0px);
    }
    50% {
      
      transform: translateY(-30px);
    }
    100% {
    
      transform: translateY(0px);
    }
  }
}

.fire {
 
  filter: drop-shadow(0px -79px 88px #281C5B);
  animation: flicker 2s infinite alternate;
}
@keyframes flicker {
  from {
    filter: drop-shadow(0px -79px 88px #281C5B)
  }
  to {
    filter: drop-shadow(0px -30px 30px #281C5B)
  }
}


/* .h-gradient-long {
  background: linear-gradient(to right, #6633CC,#FF44B8, #FF44B8,#6633CC);
  background-size: 200%;
  background-position: left;
  transition: all 500ms linear !important;
 
}

.h-gradient-long:hover {
  background-position: right;
} */


.custom-textarea {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  overflow: auto; 
  resize: none

}

.custom-textarea::-webkit-scrollbar {
  display: none; 
}